enum FILE_MIME_TYPE {
  JPG = 'image/jpeg',
  PDF = 'application/pdf',
  PNG = 'image/png',
  TIFF = 'image/tiff',
}

export type TFileMimeType =
  FILE_MIME_TYPE.JPG |
  FILE_MIME_TYPE.PDF |
  FILE_MIME_TYPE.PNG |
  FILE_MIME_TYPE.TIFF;

const fileTypes: {
  [key: string]: {
    name: string,
    alertName: string,
    type: TFileMimeType
  }
} = {
  [FILE_MIME_TYPE.JPG]: {
    name: '.jpg',
    alertName: 'JPGs',
    type: FILE_MIME_TYPE.JPG,
  },

  [FILE_MIME_TYPE.PDF]: {
    name: '.pdf',
    alertName: 'PDFs',
    type: FILE_MIME_TYPE.PDF,
  },

  [FILE_MIME_TYPE.PNG]: {
    name: '.png',
    alertName: 'PNGs',
    type: FILE_MIME_TYPE.PNG,
  },

  [FILE_MIME_TYPE.TIFF]: {
    name: '.tiff',
    alertName: 'TIFFs',
    type: FILE_MIME_TYPE.TIFF,
  },
};

Object.freeze(fileTypes);

const IMAGE_MIME_TYPE_WHITE_LIST = [
  FILE_MIME_TYPE.JPG,
  FILE_MIME_TYPE.PNG,
];

Object.freeze(IMAGE_MIME_TYPE_WHITE_LIST);

export const ALLOWED_FILE_TYPE = {
  PROFILE: 'mimeTypeProfile',
  VISUAL: 'mimeTypeVisual',
} as const;

const VISUAL_MIME_TYPE_WHITE_LIST = [
  FILE_MIME_TYPE.JPG,
  FILE_MIME_TYPE.PDF,
  FILE_MIME_TYPE.PNG,
  FILE_MIME_TYPE.TIFF,
];

export const joinedArray = (list, separator, lastSeparator) => {
  const newList = [];
  const length = list.length;

  list.forEach(
    (type, index) => {
      if ((index + 1) < length) {
        newList.push(type);
      }
    },
  );

  const lastElement = (length === 1)
    ? list[length - 1]
    : `${lastSeparator}${list[length - 1]}`;

  return `${newList.join(separator)}${lastElement}`;
};

enum FILE_TYPE {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
}

export type TFileType =
  FILE_TYPE.JPG |
  FILE_TYPE.JPEG |
  FILE_TYPE.PNG;

const defaultFileTypes: {
  [key: string]: {
    extension: string,
    type: string,
  }
} = {
  [FILE_TYPE.JPG]: {
    extension: '.jpg',
    type: 'JPEG',
  },

  [FILE_TYPE.JPEG]: {
    extension: '.jpeg',
    type: 'JPEG',
  },

  [FILE_TYPE.PNG]: {
    extension: '.png',
    type: 'PNG',
  },
};

Object.freeze(defaultFileTypes);

export {
  FILE_MIME_TYPE,
  fileTypes,
  defaultFileTypes,
  IMAGE_MIME_TYPE_WHITE_LIST,
  VISUAL_MIME_TYPE_WHITE_LIST,
};
