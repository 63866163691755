import * as JsPDF from 'jspdf';
import deLocale from 'date-fns/locale/de';
import format from 'date-fns/format';
import html2canvas from 'html2canvas';
import uuid4 from 'uuid/v4';
import { defaultFileTypes } from '@/utils/fileTypes';
import { DejaVuSans } from '@/assets/fonts/DejaVuSans-normal.js';
import { DejaVuSansBold } from '@/assets/fonts/DejaVuSans-bold.js';
import { formatDate, formatDateTime } from '@/utils/filters/format';
import { getCurrency } from '@/utils/currency';
import { http } from '@/http';
import { i18n } from '@/locales/i18n';
import { MCustomer } from '@/models/MCustomer';
import { MDeliveryBag } from '@/models/MDeliveryBag';
import { MInvoice } from '@/models/MInvoice';
import { MOrganization } from '@/models/MOrganization';
import { MSales } from '@/models/MSales';
import { MSettings } from '@/models/MSettings';
import { MTax } from '@/models/MTax';
import { MUser } from '@/models/MUser';
import { PAYMENTMETHOD, paymentMethodMap } from '@/utils/paymentMethod';
import { utIsEmpty } from '@/utils/empty';

export const mixinPDF = {
  data() {
    return {
      logo: Object,
      page: 1,
      pdf: null,
      position: 0,
      stationery: Object,
    };
  },

  computed: {
    cashId(): string {
      return 'FA29718421';
    },

    currency(): Object {
      return getCurrency(this.currentSettings?.Currency);
    },

    currentOrganization() {
      return MOrganization.query().withAll().first();
    },

    currentSettings() {
      if (this.currentOrganization) {
        return MSettings.find(this.currentOrganization.SettingsId);
      }
    },

    currentUser() {
      return MUser.query().first();
    },
  },

  methods: {
    addPage() {
      this.pdf.addPage();

      this.position = 0;

      this.page++;

      this.header();

      this.footer();

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.page', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(String(this.page), 190, this.position, null, null, 'right');

      this.position += 10;
    },

    checkPosition(): boolean {
      if (this.position > 255) {
        this.addPage();

        return true;
      }

      return false;
    },

    dottedLine(doc, xFrom, yFrom, xTo, yTo, segmentLength) {
      // Calculate line length (c)
      const a = Math.abs(xTo - xFrom);
      const b = Math.abs(yTo - yFrom);
      const c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));

      // Make sure we have an odd number of line segments (drawn or blank)
      // to fit it nicely
      const fractions = c / segmentLength;
      const adjustedSegmentLength = (Math.floor(fractions) % 2 === 0) ? (c / Math.ceil(fractions)) : (c / Math.floor(fractions));

      // Calculate x, y deltas per segment
      const deltaX = adjustedSegmentLength * (a / c);
      const deltaY = adjustedSegmentLength * (b / c);

      let curX = xFrom; let curY = yFrom;

      while (curX <= xTo && curY <= yTo) {
        doc.line(curX, curY, curX + deltaX, curY + deltaY);

        curX += 2 * deltaX;

        curY += 2 * deltaY;
      }
    },

    async exportData(rowData: any, colDef: any, title: string): Promise<any> {
      window['html2canvas'] = html2canvas;

      this.pdf = new JsPDF('P', 'mm', [
        210,
        297,
      ]);

      this.useFont();

      this.header();

      this.footer();

      this.position += 15;

      this.pdf.setFontSize(9);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.page', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(String(this.page), 190, this.position, null, null, 'right');

      this.position += 10;

      this.pdf.setFontStyle('bold');

      this.pdf.setFontSize(12);

      this.pdf.text(title, 105, this.position, null, null, 'center');

      this.position += 10;

      this.pdf.setFontSize(9);

      const newPage = this.checkPosition();

      if (newPage || this.page === 1) {
        this.getHeader(colDef);
      }

      let position = 0;

      const length = (colDef.length - 2);

      const column = 85 / length;

      this.pdf.setFontSize(8);

      rowData.forEach((row, index) => {
        for (let i = 0; i < colDef.length; i++) {
          if (i === 0) {
            this.pdf.text(row[`${colDef[i].field}`], 20, this.position);
          } else if (i === 1) {
            position = 55;

            this.pdf.text(row[`${colDef[i].field}`], position, this.position);
          } else if (i === 2) {
            position = position + 60;

            this.pdf.text(row[`${colDef[i].field}`], position, this.position);
          } else {
            position = position + column;

            this.pdf.text(row[`${colDef[i].field}`], position, this.position);
          }
        }

        this.position += 5;

        const newPage = this.checkPosition();

        if (newPage && index <= rowData.length) {
          this.getHeader(colDef);
        }
      });

      this.pdf.save(`${title}.pdf`);

      this.position = 0;
    },

    async exportOutgoingData(rowData: any, colDef: any, title: string): Promise<any> {
      window['html2canvas'] = html2canvas;

      this.pdf = new JsPDF('P', 'mm', [
        210,
        297,
      ]);

      this.useFont();

      this.header();

      this.footer();

      this.position += 15;

      this.pdf.setFontSize(9);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.page', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(String(this.page), 190, this.position, null, null, 'right');

      this.position += 10;

      this.pdf.setFontStyle('bold');

      this.pdf.setFontSize(12);

      this.pdf.text(title, 105, this.position, null, null, 'center');

      this.position += 10;

      this.pdf.setFontSize(9);

      const newPage = this.checkPosition();

      if (newPage || this.page === 1) {
        this.getOutgoingHeader(colDef);
      }

      this.pdf.setFontSize(7);

      rowData.forEach((row, index) => {
        for (let i = 0; i < colDef.length; i++) {
          if (i === 0) {
            this.pdf.text(row[`${colDef[i].field}`], 10, this.position);
          } else if (i === 1) {
            this.pdf.text(row[`${colDef[i].field}`], 25, this.position);
          } else if (i === 2) {
            this.pdf.text(row[`${colDef[i].field}`], 47, this.position);
          } else if (i === 3) {
            this.pdf.text(row[`${colDef[i].field}`], 100, this.position);
          } else if (i === 4) {
            this.pdf.text(row[`${colDef[i].field}`], 125, this.position);
          } else if (i === 5) {
            this.pdf.text(row[`${colDef[i].field}`], 140, this.position);
          } else if (i === 6) {
            this.pdf.text(row[`${colDef[i].field}`], 152, this.position);
          } else if (i === 7) {
            this.pdf.text(row[`${colDef[i].field}`], 171, this.position);
          } else {
            this.pdf.text(row[`${colDef[i].field}`], 185, this.position);
          }
        }

        this.position += 5;

        const newPage = this.checkPosition();

        if (newPage && index <= rowData.length) {
          this.getHeader(colDef);
        }
      });

      this.pdf.save(`${title}.pdf`);

      this.position = 0;
    },

    async exportSuccessData(rowDataIncome: any, rowDataOutcome: any, colDef: any, title: string): Promise<any> {
      window['html2canvas'] = html2canvas;

      this.pdf = new JsPDF('P', 'mm', [
        210,
        297,
      ]);

      this.useFont();

      this.header();

      this.footer();

      this.position += 15;

      this.pdf.setFontSize(9);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.page', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(String(this.page), 190, this.position, null, null, 'right');

      this.position += 10;

      this.pdf.setFontStyle('bold');

      this.pdf.setFontSize(12);

      this.pdf.text(title, 105, this.position, null, null, 'center');

      this.position += 10;

      this.pdf.setFontSize(11);

      this.pdf.setFontStyle('bold');

      this.pdf.setFontSize(11);

      this.pdf.text(`${this.$tc('success.property.income')}`, 20, this.position);

      this.position += 10;

      this.pdf.setFontSize(11);

      const newPage = this.checkPosition();

      if (newPage || this.page === 1) {
        this.getSuccessHeader(colDef);
      }

      this.pdf.setFontSize(8);

      let income = 0;

      rowDataIncome.forEach((row, index) => {
        income += Number(row[`${colDef[2].field}`].replace(' €', '').replace(',', '.'));

        for (let i = 0; i < colDef.length; i++) {
          if (i === 0) {
            this.pdf.text(row[`${colDef[i].field}`], 20, this.position);
          } else if (i === 1) {
            this.pdf.text(String(row[`${colDef[i].field}`]), 100, this.position);
          } else if (i === 2) {
            this.pdf.text(row[`${colDef[i].field}`], 155, this.position, null, null, 'right');
          } else {
            this.pdf.text(row[`${colDef[i].field}`], 170, this.position);
          }
        }

        this.position += 5;

        const newPage = this.checkPosition();

        if (newPage && index <= rowDataIncome.length) {
          this.getSuccessHeader(colDef);
        }
      });

      this.position -= 4;

      this.pdf.line(19, this.position, 191, this.position, 'F');

      this.position += 5;

      this.pdf.setFontSize(10);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.total', 1)}`, 20, this.position);

      this.pdf.text(`${MSales.formatedPrice(income)}`, 155, this.position, null, null, 'right');

      this.pdf.setFontSize(10);

      this.pdf.setFontStyle('normal');

      this.position += 15;

      this.pdf.setFontSize(11);

      this.pdf.setFontStyle('bold');

      this.pdf.setFontSize(11);

      this.pdf.text(`${this.$tc('success.property.outcome')}`, 20, this.position);

      this.position += 10;

      if (newPage || this.page === 1) {
        this.getSuccessHeader(colDef);
      }

      this.pdf.setFontSize(8);

      let outcome = 0;

      rowDataOutcome.forEach((row, index) => {
        outcome += Number(row[`${colDef[3].field}`].replace(' €', '').replace(',', '.'));

        for (let i = 0; i < colDef.length; i++) {
          if (i === 0) {
            this.pdf.text(row[`${colDef[i].field}`], 20, this.position);
          } else if (i === 1) {
            this.pdf.text(row[`${colDef[i].field}`], 100, this.position);
          } else if (i === 2) {
            this.pdf.text(row[`${colDef[i].field}`], 135, this.position);
          } else {
            this.pdf.text(row[`${colDef[i].field}`], 185, this.position, null, null, 'right');
          }
        }

        this.position += 5;

        const newPage = this.checkPosition();

        if (newPage && index <= rowDataOutcome.length) {
          this.getSuccessHeader(colDef);
        }
      });

      this.position -= 4;

      this.pdf.line(19, this.position, 191, this.position, 'F');

      this.position += 5;

      this.pdf.setFontSize(10);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.total', 1)}`, 20, this.position);

      this.pdf.text(`${MSales.formatedPrice(outcome)}`, 185, this.position, null, null, 'right');

      this.pdf.setFontSize(10);

      this.pdf.setFontStyle('normal');

      this.position += 15;

      /* if (newPage || this.page === 1) {
        this.getSuccessHeader(colDef, 3);
      }

      this.pdf.setFontSize(8);

      const total = income - outcome;

      this.pdf.text(`${this.$tc('success.property.income')}`, 20, this.position);

      this.pdf.text(`${MSales.formatedPrice(income)}`, 155, this.position, null, null, 'right');

      this.position += 5;

      this.pdf.text(`${this.$tc('success.property.outcome')}`, 20, this.position);

      this.pdf.text(`${MSales.formatedPrice(outcome)}`, 185, this.position, null, null, 'right');

      this.position += 1;

      this.pdf.line(19, this.position, 191, this.position, 'F');

      this.position += 5;

      this.pdf.setFontSize(10);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('success.property.success', 1)}`, 20, this.position);

      this.pdf.text(`${MSales.formatedPrice(total)}`, 185, this.position, null, null, 'right');

      this.pdf.setFontSize(10);

      this.pdf.setFontStyle('normal');*/

      this.pdf.save(`${title}.pdf`);

      this.position = 0;
    },

    async exportEarningData(rowData: any, colDef: any, title: string): Promise<any> {
      window['html2canvas'] = html2canvas;

      this.pdf = new JsPDF('P', 'mm', [
        210,
        297,
      ]);

      this.useFont();

      this.header();

      this.footer();

      this.position += 15;

      this.pdf.setFontSize(9);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.page', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(String(this.page), 190, this.position, null, null, 'right');

      this.position += 10;

      this.pdf.setFontStyle('bold');

      this.pdf.setFontSize(12);

      this.pdf.text(title, 105, this.position, null, null, 'center');

      this.position += 10;

      this.pdf.setFontSize(9);

      const newPage = this.checkPosition();

      if (newPage || this.page === 1) {
        this.getEarningHeader(colDef);
      }

      this.pdf.setFontSize(8);

      rowData.forEach((row, index) => {
        for (let i = 0; i < colDef.length; i++) {
          if (i === 0) {
            this.pdf.text(row[`${colDef[i].field}`], 10, this.position);
          } else if (i === 1) {
            this.pdf.text(row[`${colDef[i].field}`], 25, this.position);
          } else if (i === 2) {
            this.pdf.text(row[`${colDef[i].field}`], 47, this.position);
          } else if (i === 3) {
            this.pdf.text(row[`${colDef[i].field}`], 100, this.position);
          } else if (i === 4) {
            this.pdf.text(row[`${colDef[i].field}`], 125, this.position);
          } else if (i === 5) {
            this.pdf.text(row[`${colDef[i].field}`], 140, this.position);
          } else if (i === 6) {
            this.pdf.text(row[`${colDef[i].field}`], 152, this.position);
          } else if (i === 7) {
            this.pdf.text(row[`${colDef[i].field}`], 171, this.position);
          } else {
            this.pdf.text(row[`${colDef[i].field}`], 185, this.position);
          }
        }

        this.position += 5;

        const newPage = this.checkPosition();

        if (newPage && index <= rowData.length) {
          this.getEarningHeader(colDef);
        }
      });

      this.pdf.save(`${title}.pdf`);

      this.position = 0;
    },

    async exportIncomeData(rowData: any, rowDataCategory: any, title: string, save: boolean = false, dataUri: boolean = false): Promise<any> {
      window['html2canvas'] = html2canvas;

      this.pdf = new JsPDF('P', 'mm', [
        210,
        297,
      ]);

      this.useFont();

      this.header();

      this.footer();

      this.position += 15;

      this.pdf.text(`${title}`, 20, this.position);

      this.position += 5;

      const newPage = this.checkPosition();

      if (newPage || this.page === 1) {
        this.getTableHeader();
      }

      const vat = [];

      const gross = [];

      let total = 0.00;

      let totalVat = 0.00;

      rowData.forEach((sale, index) => {
        const productSale = sale;
        const tax = productSale.tax;

        if (vat[tax] === undefined) {
          vat[tax] = 0.00;

          gross[tax] = 0.00;
        }

        vat[tax] += Number((Number(productSale.price) / (100 + Number(tax)) * Number(tax)).toFixed(2));

        gross[tax] += Number(productSale.price);

        total += Number(productSale.price);

        totalVat += Number((Number(productSale.price) / (100 + Number(tax)) * Number(tax)).toFixed(2));

        this.pdf.text(`${productSale.quantity}`, 20, this.position);

        this.pdf.text(productSale.productName, 45, this.position);

        this.pdf.text(`${tax} %`, 155, this.position);

        const singlePrice = productSale.singlePrice;

        this.pdf.text(`${singlePrice}`, 130, this.position, null, null, 'right');

        const totalPrice = productSale.singlePrice.replace('€', '').replace(',', '.') * productSale.quantity.split(' ')[0];

        this.pdf.text(`${this.toFixed(totalPrice, 2)} ${this.currency?.symbol}`, 190, this.position, null, null, 'right');

        if (productSale.discount !== '0 %' && productSale.discount !== ' %') {
          this.position += 4;

          this.pdf.setFontSize(8);

          this.pdf.text(`${this.$tc('invoice.property.discount', 1)} ${productSale.discount}`, 45, this.position);

          this.pdf.text(`- ${this.toFixed(productSale.discountDifference.replace('€', '').replace(',', '.'), 2)}`, 187, this.position, null, null, 'right');

          this.pdf.text(this.currency?.symbol, 190, this.position, null, null, 'right');
        }

        this.pdf.setFontSize(9);

        this.position += 2;

        if (index < rowData.length - 1) {
          this.dottedLine(this.pdf, 19, this.position, 191, this.position, 1);
        } else {
          this.pdf.line(19, this.position, 191, this.position, 'F');
        }

        this.position += 5;

        const newPage = this.checkPosition();

        if (newPage && index <= rowData.length) {
          this.getTableHeader('invoice', total, index, rowData);
        }
      });

      this.position += 1;

      this.pdf.setFontSize(10);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.total', 1)} ${this.$tc('invoice.property.gross', 1).toUpperCase()}`, 155, this.position, null, null, 'right');

      this.pdf.text(`${this.toFixed(total, 2)}`, 187, this.position, null, null, 'right');

      this.pdf.text(this.currency?.symbol, 190, this.position, null, null, 'right');

      this.position += 5;

      this.pdf.setFontSize(8);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.incl', 1)} ${this.$tc('invoice.property.vat', 1)}`, 155, this.position, null, null, 'right');

      this.pdf.text(`${this.toFixed(totalVat, 2)}`, 187, this.position, null, null, 'right');

      this.pdf.text(this.currency?.symbol, 190, this.position, null, null, 'right');

      this.position += 15;

      let countKey = 0;

      for (const key in vat) {
        if (vat[key] !== 0.00) {
          const nextPage = this.checkPosition();

          if (nextPage || (!nextPage && this.page === 1 && countKey === 0)) {
            this.getVatTableHeader();
          }

          this.pdf.text(`${key} %`, 20, this.position);

          const net = this.toFixed(Number(Number(gross[key].toFixed(2))) - Number(Number(vat[key]).toFixed(2)), 2);

          this.pdf.text(`${net} ${this.currency?.symbol}`, 105, this.position);

          this.pdf.text(`${this.toFixed(vat[key], 2)} ${this.currency?.symbol}`, 145, this.position);

          this.pdf.text(`${this.toFixed(gross[key], 2)} ${this.currency?.symbol}`, 190, this.position, null, null, 'right');

          this.position += 4;

          countKey++;
        }
      }

      countKey = 0;

      this.position += 8;

      const taxList = MTax.all();

      rowDataCategory.forEach(category => {
        this.checkPosition();

        this.pdf.setFontSize(10);

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${category['categoryName']}: ${category.totalPrice}`, 20, this.position);

        this.position += 6;

        this.pdf.setFontSize(9);

        this.pdf.text('%', 20, this.position);

        this.pdf.text(`${this.$t('invoice.property.net')}`, 100, this.position);

        this.pdf.text(`${this.$t('invoice.property.vat')}`, 145, this.position);

        this.pdf.text(`${this.$t('invoice.property.total')}`, 190, this.position, null, null, 'right');

        this.position += 4;

        taxList.forEach(tax => {
          this.pdf.setFontSize(9);

          this.pdf.setFontStyle('normal');

          if (category[`${tax.Percent}`] !== undefined) {
            this.pdf.text(`${tax.Percent} %`, 20, this.position);

            const vat = Number(category[`${tax.Percent}`].replace(',', '.').replace(' €', '')) / (100 + Number(tax.Percent)) * Number(tax.Percent);

            const net = Number(category[`${tax.Percent}`].replace(',', '.').replace(' €', '')) - Number(vat);

            this.pdf.text(`${this.toFixed(net, 2)} ${this.currency?.symbol}`, 100, this.position);

            this.pdf.text(`${this.toFixed(vat, 2)} ${this.currency?.symbol}`, 145, this.position);

            this.pdf.text(`${category[`${tax.Percent}`]}`, 190, this.position, null, null, 'right');

            this.position += 4;
          }
        });

        this.position += 4;
      });

      this.pdf.save(`${title}.pdf`);

      this.position = 0;
    },

    footer() {
      if (
        (this.currentSettings.UseBankDetails && !utIsEmpty(this.currentSettings.StationeryUrl)) ||
        utIsEmpty(this.currentSettings.StationeryUrl)
      ) {
        this.pdf.setFontSize(9);

        this.pdf.text(`${this.$tc('invoice.property.bank', 1)}: ${this.currentSettings.Bank} * ${this.$tc('invoice.property.iban', 1)}: ${this.currentSettings.Iban} * ${this.$tc('invoice.property.bic', 1)}: ${this.currentSettings.Bic}`, 105, 280, null, null, 'center');
      }
    },

    async header() {
      if (!utIsEmpty(this.currentSettings.StationeryUrl)) {
        const stationeryAltText = this.currentSettings.StationeryAltText.split('.');
        const extension = defaultFileTypes[stationeryAltText[1]].type;
        const percent = 100 / this.stationery.width * 210;
        const imgHeight = this.stationery.height / 100 * percent;

        this.pdf.addImage(this.stationery, extension, 0, 0, 210, imgHeight);

        this.position += 65;
      } else {
        if (this.currentSettings.LogoUrl) {
          const logoAltText = this.currentSettings.LogoAltText.split('.');
          const extension = defaultFileTypes[logoAltText[1]].type;
          const percent = 100 / this.logo.width * 42;
          const imgHeight = this.logo.height / 100 * percent;

          this.pdf.addImage(this.logo, extension, 84, 5, 42, imgHeight);

          this.position += (Number(imgHeight.toFixed()) + 10);
        } else {
          this.position += 20;
        }

        this.pdf.setFontStyle('bold');

        this.pdf.setFontSize(11);

        this.position += 4;

        this.pdf.text(this.currentOrganization.Name, 190, this.position, null, null, 'right');

        this.position += 4;

        this.pdf.setFontStyle('normal');

        this.pdf.setFontSize(9);

        if (this.currentOrganization.Owner) {
          this.pdf.text(`${this.currentOrganization.Owner}`, 190, this.position, null, null, 'right');

          this.position += 4;
        }

        this.pdf.text(this.currentOrganization.Address?.AddressLine1, 190, this.position, null, null, 'right');

        this.position += 4;

        if (this.currentOrganization.Address?.AddressLine2) {
          this.pdf.text(this.currentOrganization.Address?.AddressLine2, 190, this.position, null, null, 'right');

          this.position += 4;
        }

        this.pdf.text(
          `${this.currentOrganization.Address?.Zipcode} ${this.currentOrganization.Address?.City}, ${this.currentOrganization.Address?.Country}`,
          190, this.position, null, null, 'right');

        this.position += 4;

        this.pdf.text(this.currentOrganization.Phone, 190, this.position, null, null, 'right');

        this.position += 4;

        this.pdf.text(this.currentOrganization.Email, 190, this.position, null, null, 'right');

        this.position += 4;

        this.pdf.text(this.currentOrganization.Website, 190, this.position, null, null, 'right');

        this.position += 4;
      }
    },

    loadImage(url) {
      const img = new Image();

      img.crossOrigin = 'anonymous';

      img.src = url;

      return img;
    },

    toFixed(price: number | string, decimal: number): string {
      return Number(price)
        .toFixed(decimal)
        .toString()
        .replace('.', ',');
    },

    async generateBon(invoice: MInvoice, type: string = '', save: boolean = false): Promise<any> {
      this.position = 5;

      this.pdf = new JsPDF('P', 'mm', [
        80,
        500,
      ]);

      this.useFont();

      this.pdf.setFontStyle('normal');

      this.pdf.setFontSize(8);

      if (this.currentSettings.LogoUrl) {
        const logoAltText = this.currentSettings.LogoAltText.split('.');
        const extension = defaultFileTypes[logoAltText[1]].type;
        const percent = 100 / this.logo.width * 42;
        const imgHeight = this.logo.height / 100 * percent;

        this.pdf.addImage(this.logo, extension, 15, this.position, 42, imgHeight);

        this.position += (Number(imgHeight.toFixed()) + 5);
      } else {
        this.pdf.setFontStyle('bold');

        this.pdf.setFontSize(11);

        this.position += 4;

        this.pdf.text(this.currentOrganization.Name, 36, this.position, null, null, 'center');

        this.position += 4;
      }

      this.pdf.setFontStyle('normal');

      this.pdf.setFontSize(7);

      if (this.currentOrganization.Owner) {
        this.pdf.text(`${this.$tc('organization.property.owner')}: ${this.currentOrganization.Owner}`, 36, this.position, null, null, 'center');

        this.position += 3;
      }

      this.pdf.text(this.currentOrganization.Address?.AddressLine1, 36, this.position, null, null, 'center');

      this.position += 3;

      if (this.currentOrganization.Address?.AddressLine2) {
        this.pdf.text(this.currentOrganization.Address?.AddressLine2, 36, this.position, null, null, 'center');

        this.position += 3;
      }

      this.pdf.text(`${this.currentOrganization.Address?.Zipcode} ${this.currentOrganization.Address?.City},  ${this.currentOrganization.Address?.Country}`, 36, this.position, null, null, 'center');

      this.position += 3;

      this.pdf.text(this.currentOrganization.Phone, 36, this.position, null, null, 'center');

      this.position += 3;

      this.pdf.text(this.currentOrganization.Email, 36, this.position, null, null, 'center');

      this.position += 3;

      this.pdf.text(this.currentOrganization.Website, 36, this.position, null, null, 'center');

      if (!utIsEmpty(invoice.CustomerID)) {
        this.position += 10;

        const customer = MCustomer.query().with('Address').find(invoice.CustomerID);

        this.pdf.setFontStyle('bold');

        this.pdf.setFontSize(12);

        this.pdf.text(this.$tc('invoice.property.receiver', 1), 36, this.position, null, null, 'center');

        this.pdf.setFontSize(10);

        this.position += 6;

        if (!utIsEmpty(customer.CompanyName)) {
          this.pdf.text(customer.CompanyName, 36, this.position, null, null, 'center');
        } else {
          if (customer.Sex === 'female') {
            this.pdf.text(`${this.$tc('customer.property.female')}`, 36, this.position, null, null, 'center');
          } else {
            this.pdf.text(`${this.$tc('customer.property.male')}`, 36, this.position, null, null, 'center');
          }
        }

        this.position += 4;

        this.pdf.setFontStyle('normal');

        this.pdf.text(`${customer.FirstName} ${customer.LastName}`, 36, this.position, null, null, 'center');

        this.position += 3;

        this.pdf.text(`${customer.Address.AddressLine1}`, 36, this.position, null, null, 'center');

        if (!utIsEmpty(customer.Address.AddressLine2)) {
          this.position += 3;

          this.pdf.text(`${customer.Address.AddressLine2}`, 36, this.position, null, null, 'center');
        }

        this.position += 3;

        this.pdf.text(`${customer.Address.Zipcode} ${customer.Address.City}`, 36, this.position, null, null, 'center');

        this.position += 3;

        this.pdf.text(`${customer.Address.Country}`, 36, this.position, null, null, 'center');
      }

      this.position += 10;

      this.pdf.setFontStyle('bold');

      this.pdf.setFontSize(11);

      if (type === 'reversal') {
        this.pdf.text(this.$tc('invoice.property.reversal', 1).toUpperCase(), 36, this.position, null, null, 'center');
      } else {
        this.pdf.text(this.$tc('invoice.model', 1).toUpperCase(), 36, this.position, null, null, 'center');
      }

      if (type === 'reversal') {
        const oldInvoice = MInvoice
          .query()
          .where('CancelInvoiceNumber', invoice.InvoiceNumber)
          .where('InvoiceNumber', (value) => value !== invoice.InvoiceNumber)
          .first();

        this.pdf.setFontSize(8);

        this.position += 10;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.model', 1)} ${this.$tc('invoice.property.number', 1)}: ${oldInvoice.InvoiceNumber}`, 36, this.position, null, null, 'center');

        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.model', 1)} ${this.$tc('invoice.property.date', 1)}: ${formatDateTime(oldInvoice.Date)}`, 36, this.position, null, null, 'center');
      }

      this.position += 10;

      this.pdf.setFontStyle('normal');

      this.pdf.setFontSize(7);

      this.pdf.text(`${this.$tc('invoice.property.cashier', 1)}: ${this.currentUser.FullName.split(' ')[0]}`, 5, this.position);

      this.pdf.text(`${this.$tc('invoice.property.date', 1)}: ${formatDateTime(invoice.Date)}`, 67, this.position, null, null, 'right');

      this.position += 3;

      this.pdf.text(`${this.$tc('invoice.property.cashId', 1)}: ${this.cashId}`, 5, this.position);

      this.pdf.text(`${this.$tc('invoice.property.number', 1)}: ${invoice.InvoiceNumber}`, 67, this.position, null, null, 'right');

      this.position += 6;

      this.pdf.setFontStyle('bold');

      this.pdf.setFontSize(8);

      this.pdf.text(this.$tc('invoice.property.qty', 1), 5, this.position);

      this.pdf.text(this.$tc('invoice.property.description', 1), 18, this.position);

      this.pdf.text(`${this.$tc('invoice.property.total', 1)} ${this.currency?.symbol}`, 67, this.position, null, null, 'right');

      this.position += 1;

      this.pdf.line(4, this.position, 68, this.position, 'F');

      this.position += 3;

      this.pdf.setFontStyle('normal');

      this.pdf.setFontSize(7);

      const vat = [];

      const gross = [];

      let total = 0.00;

      let totalVat = 0.00;

      const customer = MCustomer.query().with('Address').find(invoice.CustomerID);

      invoice.Sales.forEach((sale, index) => {
        const productSale = sale.asListData;
        const tax = ((customer?.CompanyName || customer?.Uid) && productSale.tax === '10' && this.currentSettings.Generalized) ? '13' : productSale.tax;

        if (vat[tax] === undefined) {
          vat[tax] = 0.00;

          gross[tax] = 0.00;
        }

        vat[tax] += Number((Number(productSale.price) / (100 + Number(tax)) * Number(tax)).toFixed(2));

        gross[tax] += Number(productSale.price);

        total += Number(productSale.price);

        totalVat += Number((Number(productSale.price) / (100 + Number(tax)) * Number(tax)).toFixed(2));

        this.pdf.text(`${productSale.quantity} ${productSale.entity}`, 5, this.position);

        this.pdf.text(productSale.productName, 18, this.position);

        this.position += 3;

        this.pdf.text(`${tax} %`, 18, this.position);

        const singlePrice = String(Number(productSale.singlePrice));

        this.pdf.text(`${MSales.formatedPrice(singlePrice)}`, 38, this.position);

        let totalPrice = Number(productSale.totalPrice);

        if (!productSale.flat) {
          totalPrice = Number(productSale.singlePrice) * Number(productSale.quantity.replace(',', '.'));
        }

        this.pdf.text(this.toFixed(totalPrice, 2), 64, this.position, null, null, 'right');

        this.pdf.text(this.currency?.symbol, 67, this.position, null, null, 'right');

        if (productSale.discount !== '0 %' && productSale.discount !== ' %') {
          this.position += 3;

          this.pdf.setFontSize(5);

          this.pdf.text(`${this.$tc('invoice.property.discount', 1)} ${productSale.discount}`, 18, this.position);

          this.pdf.text(`- ${this.toFixed(productSale.discountDifference, 2)}`, 64, this.position, null, null, 'right');

          this.pdf.text(this.currency?.symbol, 67, this.position, null, null, 'right');
        }

        this.pdf.setFontSize(7);

        this.position += 1;

        if (index < invoice.Sales.length - 1) {
          this.dottedLine(this.pdf, 5, this.position, 67, this.position, 1);
        } else {
          this.pdf.line(4, this.position, 68, this.position, 'F');
        }

        this.position += 3;
      });

      this.position += 1;

      this.pdf.setFontSize(9);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.total', 1)} ${this.$tc('invoice.property.gross', 1).toUpperCase()}`, 5, this.position);

      this.pdf.text(`${this.toFixed(total, 2)}`, 64, this.position, null, null, 'right');

      this.pdf.text(this.currency?.symbol, 67, this.position, null, null, 'right');

      this.position += 3;

      this.pdf.setFontSize(7);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.incl', 1)} ${this.$tc('invoice.property.vat', 1)}`, 5, this.position);

      this.pdf.text(`${this.toFixed(totalVat, 2)}`, 64, this.position, null, null, 'right');

      this.pdf.text(this.currency?.symbol, 67, this.position, null, null, 'right');

      this.position += 8;

      this.pdf.setFontStyle('bold');

      this.pdf.text(this.$tc('invoice.property.vat', 1), 5, this.position);

      this.pdf.text(`${this.$tc('invoice.property.net', 1).toUpperCase()} ${this.currency?.symbol}`, 26, this.position);

      this.pdf.text(`${this.$tc('invoice.property.vat', 1).toUpperCase()} ${this.currency?.symbol}`, 40, this.position);

      this.pdf.text(`${this.$tc('invoice.property.gross', 1).toUpperCase()} ${this.currency?.symbol}`, 67, this.position, null, null, 'right');

      this.position += 1;

      this.pdf.line(4, this.position, 68, this.position, 'F');

      this.position += 3;

      this.pdf.setFontStyle('normal');

      for (const key in vat) {
        if (vat[key] !== 0.00) {
          this.pdf.text(`${key} %`, 5, this.position);

          const net = this.toFixed(Number(Number(gross[key].toFixed(2))) - Number(Number(vat[key]).toFixed(2)), 2);

          this.pdf.text(`${net} ${this.currency?.symbol}`, 26, this.position);

          this.pdf.text(`${this.toFixed(vat[key], 2)} ${this.currency?.symbol}`, 40, this.position);

          this.pdf.text(`${this.toFixed(gross[key], 2)} ${this.currency?.symbol}`, 67, this.position, null, null, 'right');

          this.position += 3;
        }
      }

      this.position += 8;

      this.pdf.setFontSize(7);

      this.pdf.setFontStyle('bold');

      const paymentMethod = paymentMethodMap.get(<PAYMENTMETHOD>invoice.PaymentMethod);

      this.pdf.text(`${this.$tc('invoice.property.paymentMethod', 1)}: ${paymentMethod.displayName}`, 35, this.position, null, null, 'center');

      if (type !== 'reversal') {
        if (invoice.PaymentMethod !== PAYMENTMETHOD.TRANSACTION) {
          this.position += 5;

          this.pdf.text(this.$tc('invoice.text.paymentReceived', 1), 35, this.position, null, null, 'center');

          this.position += 4;

          this.pdf.text(formatDateTime(invoice.Date), 35, this.position, null, null, 'center');
        } else {
          this.position += 5;

          const paymentTransaction = this.pdf.splitTextToSize(
            `${this.$t('invoice.text.paymentTransaction', {
              days: this.currentSettings.TermOfPayment,
            })}`,
            50,
          );

          this.pdf.text(paymentTransaction, 36, this.position, null, null, 'center');
        }

        this.position += 7;

        const invoiceEndText = this.pdf.splitTextToSize(this.currentSettings.InvoiceEndText, 50);

        this.pdf.text(invoiceEndText, 36, this.position, null, null, 'center');
      } else {
        this.position += 5;

        const reversalText = this.pdf.splitTextToSize(`${this.$tc('invoice.text.reversalText', 1)}`, 50);

        this.pdf.text(reversalText, 36, this.position, null, null, 'center');
      }

      this.position = 0;

      if (save) {
        if (type === 'reversal') {
          this.pdf.save(`${this.$tc('invoice.model', 1)}-${invoice.CancelInvoiceNumber}.pdf`);
        } else {
          this.pdf.save(`${this.$tc('invoice.model', 1)}-${invoice.InvoiceNumber}.pdf`);
        }
      } else {
        this.pdf.autoPrint();

        return this.pdf.output('bloburl');
      }
    },

    async generateDelivery(delivery: MDeliveryBag, save: boolean = false): Promise<any> {
      window['html2canvas'] = html2canvas;

      this.pdf = new JsPDF('P', 'mm', [
        210,
        297,
      ]);

      this.useFont();

      this.header();

      this.footer();

      if (!utIsEmpty(delivery.CustomerID)) {
        const customer = MCustomer.query().with('Address').find(delivery.CustomerID);

        this.pdf.setFontStyle('bold');

        this.pdf.setFontSize(10);

        if (!utIsEmpty(customer.CompanyName)) {
          this.pdf.text(customer.CompanyName, 20, this.position, null, null, 'left');
        } else {
          if (customer.Sex === 'female') {
            this.pdf.text(`${this.$tc('customer.property.female')}`, 20, this.position, null, null, 'left');
          } else {
            this.pdf.text(`${this.$tc('customer.property.male')}`, 20, this.position, null, null, 'left');
          }
        }

        this.position += 4;

        this.pdf.setFontStyle('normal');

        this.pdf.text(`${customer.FirstName} ${customer.LastName}`, 20, this.position, null, null, 'left');

        this.position += 4;

        this.pdf.text(`${customer.Address.AddressLine1}`, 20, this.position, null, null, 'left');

        if (!utIsEmpty(customer.Address.AddressLine2)) {
          this.position += 4;

          this.pdf.text(`${customer.Address.AddressLine2}`, 20, this.position, null, null, 'left');
        }

        this.position += 4;

        this.pdf.text(`${customer.Address.Zipcode} ${customer.Address.City}`, 20, this.position, null, null, 'left');

        this.position += 4;

        this.pdf.text(`${customer.Address.Country}`, 20, this.position, null, null, 'left');
      }

      this.position += 15;

      this.pdf.setFontSize(9);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('delivery.property.arranger', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(this.currentUser.FullName, 190, this.position, null, null, 'right');

      this.position += 4;

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('delivery.model', 1)} ${this.$tc('delivery.property.number', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(delivery.DeliveryNumber, 190, this.position, null, null, 'right');

      this.position += 4;

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('delivery.model', 1)} ${this.$tc('invoice.property.date', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(formatDateTime(delivery.Date), 190, this.position, null, null, 'right');

      this.position += 4;

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.page', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(String(this.page), 190, this.position, null, null, 'right');

      this.position += 10;

      this.pdf.setFontStyle('bold');

      this.pdf.setFontSize(13);

      this.pdf.text(`${this.$tc('delivery.model', 1)} ${delivery.DeliveryNumber}`, 20, this.position);

      const newPage = this.checkPosition();

      if (newPage || this.page === 1) {
        this.getTableHeader('delivery');
      }

      const customer = MCustomer.find(delivery.CustomerID);

      delivery.Sales.forEach((sale, index) => {
        const productSale = sale.asListData;
        const tax = ((customer?.CompanyName || customer?.Uid) && productSale.tax === '10' && this.currentSettings.Generalized) ? '13' : productSale.tax;

        this.pdf.text(`${productSale.quantity} ${productSale.entity}`, 20, this.position);

        this.pdf.text(productSale.productName, 45, this.position);

        this.pdf.text(`${tax} %`, 155, this.position);

        const singlePrice = (productSale.flat) ? String(Number(productSale.totalPrice)) : String(Number(productSale.singlePrice));

        this.pdf.text(`${MSales.formatedPrice(singlePrice)}`, 130, this.position, null, null, 'right');

        if (productSale.discount !== '0 %' && productSale.discount !== ' %') {
          this.position += 4;

          this.pdf.setFontSize(8);

          this.pdf.text(`${this.$tc('invoice.property.discount', 1)} ${productSale.discount}`, 45, this.position);

          this.pdf.text(`- ${this.toFixed(productSale.discountSingleDifference, 2)} ${this.currency?.symbol}`, 130, this.position, null, null, 'right');
        }

        this.pdf.setFontSize(9);

        this.position += 2;

        if (index < delivery.Sales.length - 1) {
          this.dottedLine(this.pdf, 19, this.position, 191, this.position, 1);
        } else {
          this.pdf.line(19, this.position, 191, this.position, 'F');
        }

        this.position += 5;

        const newPage = this.checkPosition();

        if (newPage && index < delivery.Sales.length) {
          this.getTableHeader('delivery');
        }
      });

      this.position += 8;

      this.checkPosition();

      this.pdf.setFontSize(10);

      this.pdf.setFontStyle('bold');

      this.position += 5;

      this.pdf.text(`${this.$tc('delivery.text.deliveryReceived', 1)} ${formatDate(delivery.Date)}`, 20, this.position);

      this.position += 7;

      this.pdf.text(`${this.$tc('delivery.property.signature', 1)}:`, 20, this.position);

      this.position = 0;

      if (save) {
        this.pdf.save(`${this.$tc('delivery.model', 1)}-${delivery.DeliveryNumber}.pdf`);
      } else {
        this.pdf.autoPrint();

        return this.pdf.output('bloburl');
      }
    },

    async generatePdf(invoice: MInvoice, type: string = '', save: boolean = false, dataUri: boolean = false): Promise<any> {
      window['html2canvas'] = html2canvas;

      this.pdf = new JsPDF('P', 'mm', [
        210,
        297,
      ]);

      this.useFont();

      this.header();

      this.footer();

      if (!utIsEmpty(invoice.CustomerID)) {
        const customer = MCustomer.query().with('Address').find(invoice.CustomerID);

        this.pdf.setFontStyle('bold');

        this.pdf.setFontSize(10);

        if (!utIsEmpty(customer.CompanyName)) {
          this.pdf.text(customer.CompanyName, 20, this.position, null, null, 'left');
        } else {
          if (customer.Sex === 'female') {
            this.pdf.text(`${this.$tc('customer.property.female')}`, 20, this.position, null, null, 'left');
          } else {
            this.pdf.text(`${this.$tc('customer.property.male')}`, 20, this.position, null, null, 'left');
          }
        }

        this.position += 4;

        this.pdf.setFontStyle('normal');

        this.pdf.text(`${customer.FirstName} ${customer.LastName}`, 20, this.position, null, null, 'left');

        this.position += 4;

        this.pdf.text(`${customer.Address.AddressLine1}`, 20, this.position, null, null, 'left');

        if (!utIsEmpty(customer.Address.AddressLine2)) {
          this.position += 4;

          this.pdf.text(`${customer.Address.AddressLine2}`, 20, this.position, null, null, 'left');
        }

        this.position += 4;

        this.pdf.text(`${customer.Address.Zipcode} ${customer.Address.City}`, 20, this.position, null, null, 'left');

        this.position += 4;

        this.pdf.text(`${customer.Address.Country}`, 20, this.position, null, null, 'left');
      }

      this.position += 15;

      this.pdf.setFontSize(9);

      const paymentMethod = paymentMethodMap.get(<PAYMENTMETHOD>invoice.PaymentMethod);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.cashier', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(this.currentUser.FullName, 190, this.position, null, null, 'right');

      this.position += 4;

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.paymentMethod', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(paymentMethod.displayName, 190, this.position, null, null, 'right');

      if (type === 'reversal') {
        const oldInvoice = MInvoice
          .query()
          .where('CancelInvoiceNumber', invoice.InvoiceNumber)
          .where('InvoiceNumber', (value) => value !== invoice.InvoiceNumber)
          .first();

        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.model', 1)} ${this.$tc('invoice.property.number', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(oldInvoice.InvoiceNumber, 190, this.position, null, null, 'right');

        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.model', 1)} ${this.$tc('invoice.property.date', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(formatDateTime(oldInvoice.Date), 190, this.position, null, null, 'right');

        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.property.reversal', 1)} ${this.$tc('invoice.property.number', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(invoice.CancelInvoiceNumber, 190, this.position, null, null, 'right');

        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.property.reversal', 1)} ${this.$tc('invoice.property.date', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(formatDateTime(invoice.CancelInvoiceDate), 190, this.position, null, null, 'right');
      } else {
        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.model', 1)} ${this.$tc('invoice.property.number', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(invoice.InvoiceNumber, 190, this.position, null, null, 'right');

        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.model', 1)} ${this.$tc('invoice.property.date', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(formatDateTime(invoice.Date), 190, this.position, null, null, 'right');
      }

      if (invoice.DeliveryInvoice) {
        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.property.deliveryPeriod', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(invoice.CancelInvoiceNumber, 190, this.position, null, null, 'right');
      }

      this.position += 4;

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.page', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(String(this.page), 190, this.position, null, null, 'right');

      this.position += 10;

      this.pdf.setFontStyle('bold');

      this.pdf.setFontSize(13);

      if (type === 'reversal') {
        this.pdf.text(`${this.$tc('invoice.property.reversal', 1)} ${invoice.CancelInvoiceNumber}`, 20, this.position);
      } else {
        this.pdf.text(`${this.$tc('invoice.model', 1)} ${invoice.InvoiceNumber}`, 20, this.position);
      }

      if (!utIsEmpty(invoice.AdditionalText)) {
        this.position += 10;

        this.pdf.setFontStyle('normal');

        this.pdf.setFontSize(11);

        this.pdf.text(`${invoice.AdditionalText}`, 20, this.position);
      }

      const newPage = this.checkPosition();

      if (newPage || this.page === 1) {
        this.getTableHeader();
      }

      const vat = [];

      const gross = [];

      let total = 0.00;

      let totalVat = 0.00;

      const customer = MCustomer.find(invoice.CustomerID);

      invoice.Sales.forEach((sale, index) => {
        const productSale = sale.asListData;
        const tax = ((customer?.CompanyName || customer?.Uid) && productSale.tax === '10' && this.currentSettings.Generalized) ? '13' : productSale.tax;

        if (vat[tax] === undefined) {
          vat[tax] = 0.00;

          gross[tax] = 0.00;
        }

        vat[tax] += Number((Number(productSale.price) / (100 + Number(tax)) * Number(tax)).toFixed(2));

        gross[tax] += Number(productSale.price);

        total += Number(productSale.price);

        totalVat += Number((Number(productSale.price) / (100 + Number(tax)) * Number(tax)).toFixed(2));

        this.pdf.text(`${productSale.quantity} ${productSale.entity}`, 20, this.position);

        this.pdf.text(productSale.productName, 45, this.position);

        this.pdf.text(`${tax} %`, 155, this.position);

        const singlePrice = String(Number(productSale.singlePrice));

        this.pdf.text(`${MSales.formatedPrice(singlePrice)}`, 130, this.position, null, null, 'right');

        let totalPrice = Number(productSale.totalPrice);

        if (!productSale.flat) {
          totalPrice = Number(productSale.singlePrice) * Number(productSale.quantity.replace(',', '.'));
        }

        this.pdf.text(this.toFixed(totalPrice, 2), 187, this.position, null, null, 'right');

        this.pdf.text(this.currency?.symbol, 190, this.position, null, null, 'right');

        if (productSale.discount !== '0 %' && productSale.discount !== ' %') {
          this.position += 4;

          this.pdf.setFontSize(8);

          this.pdf.text(`${this.$tc('invoice.property.discount', 1)} ${productSale.discount}`, 45, this.position);

          this.pdf.text(`- ${this.toFixed(productSale.discountDifference, 2)}`, 187, this.position, null, null, 'right');

          this.pdf.text(this.currency?.symbol, 190, this.position, null, null, 'right');
        }

        this.pdf.setFontSize(9);

        this.position += 2;

        if (index < invoice.Sales.length - 1) {
          this.dottedLine(this.pdf, 19, this.position, 191, this.position, 1);
        } else {
          this.pdf.line(19, this.position, 191, this.position, 'F');
        }

        this.position += 5;

        const newPage = this.checkPosition();

        if (newPage && index <= invoice.Sales.length) {
          this.getTableHeader('invoice', total, index, invoice.Sales);
        }
      });

      this.position += 1;

      this.pdf.setFontSize(10);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.total', 1)} ${this.$tc('invoice.property.gross', 1).toUpperCase()}`, 155, this.position, null, null, 'right');

      this.pdf.text(`${this.toFixed(total, 2)}`, 187, this.position, null, null, 'right');

      this.pdf.text(this.currency?.symbol, 190, this.position, null, null, 'right');

      this.position += 5;

      this.pdf.setFontSize(8);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.incl', 1)} ${this.$tc('invoice.property.vat', 1)}`, 155, this.position, null, null, 'right');

      this.pdf.text(`${this.toFixed(totalVat, 2)}`, 187, this.position, null, null, 'right');

      this.pdf.text(this.currency?.symbol, 190, this.position, null, null, 'right');

      this.position += 15;

      let countKey = 0;

      for (const key in vat) {
        if (vat[key] !== 0.00) {
          const nextPage = this.checkPosition();

          if (nextPage || (!nextPage && this.page === 1 && countKey === 0)) {
            this.getVatTableHeader();
          }

          this.pdf.text(`${key} %`, 20, this.position);

          const net = this.toFixed(Number(Number(gross[key].toFixed(2))) - Number(Number(vat[key]).toFixed(2)), 2);

          this.pdf.text(`${net} ${this.currency?.symbol}`, 105, this.position);

          this.pdf.text(`${this.toFixed(vat[key], 2)} ${this.currency?.symbol}`, 145, this.position);

          this.pdf.text(`${this.toFixed(gross[key], 2)} ${this.currency?.symbol}`, 190, this.position, null, null, 'right');

          this.position += 4;

          countKey++;
        }
      }

      this.position += 8;

      this.checkPosition();

      this.pdf.setFontSize(10);

      this.pdf.setFontStyle('bold');

      if (type !== 'reversal') {
        if (invoice.PaymentMethod !== PAYMENTMETHOD.TRANSACTION) {
          this.position += 5;

          this.pdf.text(`${this.$tc('invoice.text.paymentReceived', 1)} ${formatDate(invoice.Date)}`, 20, this.position);
        } else {
          this.position += 5;

          this.pdf.text(`${this.$tc('invoice.text.paymentTransaction', {
            days: this.currentSettings.TermOfPayment,
          })}`, 20, this.position);
        }

        this.position += 7;

        const endText = this.pdf.splitTextToSize(this.currentSettings.InvoiceEndText, 150);

        this.pdf.text(endText, 20, this.position);
      } else {
        this.position += 5;

        this.pdf.text(`${this.$tc('invoice.text.reversalText', 1)}`, 20, this.position);
      }

      this.position = 0;

      if (save) {
        if (type === 'reversal') {
          this.pdf.save(`${this.$tc('invoice.model', 1)}-${invoice.CancelInvoiceNumber}.pdf`);
        } else {
          this.pdf.save(`${this.$tc('invoice.model', 1)}-${invoice.InvoiceNumber}.pdf`);
        }
      } else {
        if (dataUri) {
          return this.pdf.output('datauri');
        } else {
          this.pdf.autoPrint();

          return this.pdf.output('bloburl');
        }
      }
    },

    async generateDeliveryInvoicePdf(invoice: MInvoice, type: string = '', save: boolean = false, dataUri: boolean = false): Promise<any> {
      window['html2canvas'] = html2canvas;

      const id = (type === 'reversal')
        ? MInvoice
          .query()
          .where('CancelInvoiceNumber', invoice.InvoiceNumber)
          .where('InvoiceNumber', (value) => value !== invoice.InvoiceNumber)
          .first()
          ?.Id
        : invoice.Id;

      const deliveryBags = MDeliveryBag
        .query()
        .withAllRecursive()
        .where('InvoiceID', id)
        .get();

      let options = null;
      const lang = navigator.language;

      if (lang.indexOf('de') !== -1) {
        options = {
          locale: deLocale,
        };
      }

      const deliveryPeriod = format(new Date(deliveryBags[0].Date), 'MMMM', options);

      this.pdf = new JsPDF('P', 'mm', [
        210,
        297,
      ]);

      this.useFont();

      this.header();

      this.footer();

      if (!utIsEmpty(invoice.CustomerID)) {
        const customer = MCustomer.query().with('Address').find(invoice.CustomerID);

        this.pdf.setFontStyle('bold');

        this.pdf.setFontSize(10);

        if (!utIsEmpty(customer.CompanyName)) {
          this.pdf.text(customer.CompanyName, 20, this.position, null, null, 'left');
        } else {
          if (customer.Sex === 'female') {
            this.pdf.text(`${this.$tc('customer.property.female')}`, 20, this.position, null, null, 'left');
          } else {
            this.pdf.text(`${this.$tc('customer.property.male')}`, 20, this.position, null, null, 'left');
          }
        }

        this.position += 4;

        this.pdf.setFontStyle('normal');

        this.pdf.text(`${customer.FirstName} ${customer.LastName}`, 20, this.position, null, null, 'left');

        this.position += 4;

        this.pdf.text(`${customer.Address.AddressLine1}`, 20, this.position, null, null, 'left');

        if (!utIsEmpty(customer.Address.AddressLine2)) {
          this.position += 4;

          this.pdf.text(`${customer.Address.AddressLine2}`, 20, this.position, null, null, 'left');
        }

        this.position += 4;

        this.pdf.text(`${customer.Address.Zipcode} ${customer.Address.City}`, 20, this.position, null, null, 'left');

        this.position += 4;

        this.pdf.text(`${customer.Address.Country}`, 20, this.position, null, null, 'left');
      }

      this.position += 15;

      this.pdf.setFontSize(9);

      const paymentMethod = paymentMethodMap.get(<PAYMENTMETHOD>invoice.PaymentMethod);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.cashier', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(this.currentUser.FullName, 190, this.position, null, null, 'right');

      this.position += 4;

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.paymentMethod', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(paymentMethod.displayName, 190, this.position, null, null, 'right');

      if (type === 'reversal') {
        const oldInvoice = MInvoice
          .query()
          .where('CancelInvoiceNumber', invoice.InvoiceNumber)
          .where('InvoiceNumber', (value) => value !== invoice.InvoiceNumber)
          .first();

        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.model', 1)} ${this.$tc('invoice.property.number', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(oldInvoice.InvoiceNumber, 190, this.position, null, null, 'right');

        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.model', 1)} ${this.$tc('invoice.property.date', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(formatDateTime(oldInvoice.Date), 190, this.position, null, null, 'right');

        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.property.reversal', 1)} ${this.$tc('invoice.property.number', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(invoice.CancelInvoiceNumber, 190, this.position, null, null, 'right');

        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.property.reversal', 1)} ${this.$tc('invoice.property.date', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(formatDateTime(invoice.CancelInvoiceDate), 190, this.position, null, null, 'right');
      } else {
        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.model', 1)} ${this.$tc('invoice.property.number', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(invoice.InvoiceNumber, 190, this.position, null, null, 'right');

        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.model', 1)} ${this.$tc('invoice.property.date', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(formatDateTime(invoice.Date), 190, this.position, null, null, 'right');
      }

      if (invoice.DeliveryInvoice) {
        this.position += 4;

        this.pdf.setFontStyle('bold');

        this.pdf.text(`${this.$tc('invoice.property.deliveryPeriod', 1)}:`, 158, this.position, null, null, 'right');

        this.pdf.setFontStyle('normal');

        this.pdf.text(deliveryPeriod, 190, this.position, null, null, 'right');
      }

      this.position += 4;

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.page', 1)}:`, 158, this.position, null, null, 'right');

      this.pdf.setFontStyle('normal');

      this.pdf.text(String(this.page), 190, this.position, null, null, 'right');

      this.position += 10;

      this.pdf.setFontStyle('bold');

      this.pdf.setFontSize(13);

      if (type === 'reversal') {
        this.pdf.text(`${this.$tc('invoice.property.reversal', 1)} ${invoice.CancelInvoiceNumber}`, 20, this.position);
      } else {
        this.pdf.text(`${this.$tc('invoice.model', 1)} ${invoice.InvoiceNumber}`, 20, this.position);
      }

      if (!utIsEmpty(invoice.AdditionalText)) {
        this.position += 10;

        this.pdf.setFontStyle('normal');

        this.pdf.setFontSize(11);

        this.pdf.text(`${invoice.AdditionalText}`, 20, this.position);
      }

      const newPage = this.checkPosition();

      if (newPage || this.page === 1) {
        this.getTableHeader();
      }

      const vat = [];

      const gross = [];

      let total = 0.00;

      let totalVat = 0.00;

      const customer = MCustomer.find(invoice.CustomerID);

      const negative = type === 'reversal' ? '-' : '';

      deliveryBags.forEach(deliveryBag => {
        this.position += 5;

        this.pdf.setFontStyle('bold');

        this.pdf.setFontSize(10);

        this.pdf.text(`${this.$tc('delivery.model', 1)}: ${formatDate(deliveryBag.Date)}`, 20, this.position);

        this.pdf.setFontStyle('normal');

        this.pdf.setFontSize(10);

        this.position += 5;

        deliveryBag.Sales.forEach((sale, index) => {
          const productSale = sale.asListData;
          const tax = ((customer?.CompanyName || customer?.Uid) && productSale.tax === '10' && this.currentSettings.Generalized) ? '13' : productSale.tax;

          if (vat[tax] === undefined) {
            vat[tax] = 0.00;

            gross[tax] = 0.00;
          }

          vat[tax] += Number((Number(productSale.price) / (100 + Number(tax)) * Number(tax)).toFixed(2));

          gross[tax] += Number(productSale.price);

          total += Number(productSale.price);

          totalVat += Number((Number(productSale.price) / (100 + Number(tax)) * Number(tax)).toFixed(2));

          this.pdf.text(`${productSale.quantity} ${productSale.entity}`, 20, this.position);

          this.pdf.text(productSale.productName, 45, this.position);

          this.pdf.text(`${tax} %`, 155, this.position);

          const singlePrice = String(Number(productSale.singlePrice));

          this.pdf.text(`${MSales.formatedPrice(singlePrice)}`, 130, this.position, null, null, 'right');

          const totalPrice = `${negative}${Number(productSale.totalPrice)}`;

          this.pdf.text(this.toFixed(totalPrice, 2), 187, this.position, null, null, 'right');

          this.pdf.text(this.currency?.symbol, 190, this.position, null, null, 'right');

          if (productSale.discount !== '0 %' && productSale.discount !== ' %') {
            this.position += 4;

            this.pdf.setFontSize(8);

            this.pdf.text(`${this.$tc('invoice.property.discount', 1)} ${productSale.discount}`, 45, this.position);

            this.pdf.text(`- ${this.toFixed(productSale.discountDifference, 2)}`, 187, this.position, null, null, 'right');

            this.pdf.text(this.currency?.symbol, 190, this.position, null, null, 'right');
          }

          this.pdf.setFontSize(9);

          this.position += 2;

          if (index < invoice.Sales.length - 1) {
            this.dottedLine(this.pdf, 19, this.position, 191, this.position, 1);
          } else {
            this.pdf.line(19, this.position, 191, this.position, 'F');
          }

          this.position += 5;

          const newPage = this.checkPosition();

          if (newPage && index <= invoice.Sales.length) {
            this.getTableHeader('invoice', total, index, invoice.Sales);
          }
        });
      });

      this.position += 1;

      this.pdf.setFontSize(10);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.total', 1)} ${this.$tc('invoice.property.gross', 1).toUpperCase()}`, 155, this.position, null, null, 'right');

      this.pdf.text(`${negative}${this.toFixed(total, 2)}`, 187, this.position, null, null, 'right');

      this.pdf.text(this.currency?.symbol, 190, this.position, null, null, 'right');

      this.position += 5;

      this.pdf.setFontSize(8);

      this.pdf.setFontStyle('bold');

      this.pdf.text(`${this.$tc('invoice.property.incl', 1)} ${this.$tc('invoice.property.vat', 1)}`, 155, this.position, null, null, 'right');

      this.pdf.text(`${negative}${this.toFixed(totalVat, 2)}`, 187, this.position, null, null, 'right');

      this.pdf.text(this.currency?.symbol, 190, this.position, null, null, 'right');

      this.position += 15;

      let countKey = 0;

      for (const key in vat) {
        if (vat[key] !== 0.00) {
          const nextPage = this.checkPosition();

          if (nextPage || (!nextPage && this.page === 1 && countKey === 0)) {
            this.getVatTableHeader();
          }

          this.pdf.text(`${key} %`, 20, this.position);

          const net = this.toFixed(Number(Number(gross[key].toFixed(2))) - Number(Number(vat[key]).toFixed(2)), 2);

          this.pdf.text(`${negative}${net} ${this.currency?.symbol}`, 105, this.position);

          this.pdf.text(`${negative}${this.toFixed(vat[key], 2)} ${this.currency?.symbol}`, 145, this.position);

          this.pdf.text(`${negative}${this.toFixed(gross[key], 2)} ${this.currency?.symbol}`, 190, this.position, null, null, 'right');

          this.position += 4;

          countKey++;
        }
      }

      this.position += 8;

      this.checkPosition();

      this.pdf.setFontSize(10);

      this.pdf.setFontStyle('bold');

      if (type !== 'reversal') {
        if (invoice.PaymentMethod !== PAYMENTMETHOD.TRANSACTION) {
          this.position += 5;

          this.pdf.text(`${this.$tc('invoice.text.paymentReceived', 1)} ${formatDate(invoice.Date)}`, 20, this.position);
        } else {
          this.position += 5;

          this.pdf.text(`${this.$tc('invoice.text.paymentTransaction', {
            days: this.currentSettings.TermOfPayment,
          })}`, 20, this.position);
        }

        this.position += 7;

        const endText = this.pdf.splitTextToSize(this.currentSettings.InvoiceEndText, 150);

        this.pdf.text(endText, 20, this.position);
      } else {
        this.position += 5;

        this.pdf.text(`${this.$tc('invoice.text.reversalText', 1)}`, 20, this.position);
      }

      this.position = 0;

      if (save) {
        if (type === 'reversal') {
          this.pdf.save(`${this.$tc('invoice.model', 1)}-${invoice.CancelInvoiceNumber}.pdf`);
        } else {
          this.pdf.save(`${this.$tc('invoice.model', 1)}-${invoice.InvoiceNumber}.pdf`);
        }
      } else {
        if (dataUri) {
          return this.pdf.output('datauri');
        } else {
          this.pdf.autoPrint();

          return this.pdf.output('bloburl');
        }
      }
    },

    getHeader(colDef): void {
      this.pdf.setFontStyle('bold');

      let position = 0;

      const length = (colDef.length - 2);

      const column = 85 / length;

      colDef.forEach((heading, index) => {
        if (index === 0) {
          this.pdf.text(heading.headerName, 20, this.position);
        } else if (index === 1) {
          position = 55;

          this.pdf.text(heading.headerName, position, this.position);
        } else if (index === 2) {
          position = position + 60;

          this.pdf.text(heading.headerName, position, this.position);
        } else {
          position = position + column;

          this.pdf.text(heading.headerName, position, this.position);
        }
      });

      this.position += 1;

      this.pdf.line(19, this.position, 191, this.position, 'F');

      this.position += 4;

      this.pdf.setFontStyle('normal');
    },

    getSuccessHeader(colDef, col: number = 4): void {
      this.pdf.setFontSize(11);

      this.pdf.setFontStyle('bold');

      this.pdf.text(colDef[0].headerName, 20, this.position);

      if (col === 4) {
        this.pdf.text(colDef[1].headerName, 100, this.position);
      }

      this.pdf.text(colDef[2].headerName, 130, this.position);

      this.pdf.text(colDef[3].headerName, 165, this.position);

      this.position += 1;

      this.pdf.line(19, this.position, 191, this.position, 'F');

      this.position += 4;

      this.pdf.setFontStyle('normal');
    },

    getEarningHeader(colDef): void {
      this.pdf.setFontSize(7);

      this.pdf.setFontStyle('bold');

      this.pdf.text(colDef[0].headerName, 10, this.position);

      this.pdf.text(colDef[1].headerName, 25, this.position);

      this.pdf.text(colDef[2].headerName, 47, this.position);

      this.pdf.text(colDef[3].headerName, 100, this.position);

      this.pdf.text(colDef[4].headerName, 125, this.position);

      this.pdf.text(colDef[5].headerName, 140, this.position);

      this.pdf.text(colDef[6].headerName, 153, this.position);

      this.pdf.text(colDef[7].headerName, 170, this.position);

      this.pdf.text(colDef[8].headerName, 182, this.position);

      this.position += 1;

      this.pdf.line(9, this.position, 201, this.position, 'F');

      this.position += 4;

      this.pdf.setFontStyle('normal');
    },

    getOutgoingHeader(colDef): void {
      this.pdf.setFontSize(7);

      this.pdf.setFontStyle('bold');

      this.pdf.text(colDef[0].headerName, 10, this.position);

      this.pdf.text(colDef[1].headerName, 25, this.position);

      this.pdf.text(colDef[2].headerName, 47, this.position);

      this.pdf.text(colDef[3].headerName, 100, this.position);

      this.pdf.text(colDef[4].headerName, 125, this.position);

      this.pdf.text(colDef[5].headerName, 140, this.position);

      this.pdf.text(colDef[6].headerName, 153, this.position);

      this.pdf.text(colDef[7].headerName, 170, this.position);

      this.pdf.text(colDef[8].headerName, 182, this.position);

      this.position += 1;

      this.pdf.line(9, this.position, 201, this.position, 'F');

      this.position += 4;

      this.pdf.setFontStyle('normal');
    },

    getTableHeader(
      type: string = 'invoice',
      total: string = null,
      index: number = null,
      rowData: any = null,
    ): void {
      this.position += 8;

      this.pdf.setFontStyle('bold');

      this.pdf.setFontSize(10);

      this.pdf.text(this.$tc('invoice.property.qty', 1), 20, this.position);

      this.pdf.text(this.$tc('invoice.property.description', 1), 45, this.position);

      this.pdf.text(`${this.$tc('invoice.property.single', 1)} ${this.currency?.symbol}`, 130, this.position, null, null, 'right');

      this.pdf.text(`${this.$tc('invoice.property.vat', 1)} %`, 155, this.position);

      if (type === 'invoice') {
        this.pdf.text(`${this.$tc('invoice.property.total', 1)} ${this.currency?.symbol}`, 190, this.position, null, null, 'right');
      }

      this.position += 1;

      this.pdf.line(18, this.position, 192, this.position, 'F');

      this.position += 4;

      this.pdf.setFontStyle('normal');

      this.pdf.setFontSize(9);

      if (total !== null) {
        this.pdf.text(`${this.$tc('invoice.property.amountCarriedOver')}`, 130, this.position, null, null, 'right');

        this.pdf.text(this.toFixed(total, 2), 187, this.position, null, null, 'right');

        this.pdf.text(this.currency?.symbol, 190, this.position, null, null, 'right');

        this.position += 2;

        if (index < rowData.length - 1) {
          this.dottedLine(this.pdf, 19, this.position, 191, this.position, 1);
        } else {
          this.pdf.line(19, this.position, 191, this.position, 'F');
        }

        this.position += 5;
      }
    },

    getVatTableHeader(): void {
      this.pdf.setFontStyle('bold');

      this.pdf.text(this.$tc('invoice.property.vat', 1), 20, this.position);

      this.pdf.text(`${this.$tc('invoice.property.net', 1).toUpperCase()} ${this.currency?.symbol}`, 105, this.position);

      this.pdf.text(`${this.$tc('invoice.property.vat', 1).toUpperCase()} ${this.currency?.symbol}`, 145, this.position);

      this.pdf.text(`${this.$tc('invoice.property.gross', 1).toUpperCase()} ${this.currency?.symbol}`, 190, this.position, null, null, 'right');

      this.position += 1;

      this.pdf.line(19, this.position, 191, this.position, 'F');

      this.position += 4;

      this.pdf.setFontStyle('normal');
    },

    useFont(): void {
      this.pdf.addFileToVFS('@/assets/fonts/DejaVuSans.ttf', DejaVuSans);

      this.pdf.addFont('@/assets/fonts/DejaVuSans.ttf', 'pdffont', 'normal');

      this.pdf.addFileToVFS('@/assets/fonts/DejaVuSans-bold.ttf', DejaVuSansBold);

      this.pdf.addFont('@/assets/fonts/DejaVuSans-bold.ttf', 'pdffont', 'bold');

      this.pdf.setFont('pdffont');
    },
  },

  async beforeMount() {
    if (this.currentSettings) {
      const logoSource = this.currentSettings.organizationImageURL();

      const responseLogo = await fetch(logoSource, {
        headers: {
          Authorization: http.defaults.headers.common.Authorization,
        },
      });

      // Create an object URL from the data.
      const blobLogo = await responseLogo.blob();

      this.logo = this.loadImage(URL.createObjectURL(blobLogo));

      const source = this.currentSettings.organizationImageURL('Stationery');

      // Fetch the image.

      const response = await fetch(source, {
        headers: {
          Authorization: http.defaults.headers.common.Authorization,
        },
      });

      // Create an object URL from the data.
      const blob = await response.blob();

      this.stationery = this.loadImage(URL.createObjectURL(blob));
    }
  },
};

